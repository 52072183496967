import React, { useState, useEffect } from "react"
import { StaticQuery, graphql, withPrefix } from "gatsby";
import { useLocation } from '@reach/router';
import { Global } from "@emotion/core"
import GlobalCSS from '../components/GlobalCSS'
import TopNavigation from "../components/TopNavigation"
import Footer from '../components/footer/Footer'
import SEO  from './SEO'
import Helmet from 'react-helmet'
import { siteMetadata }from '../../gatsby-config';
import axios from 'axios'
import useDataLayer from '../hooks/useDataLayer'
import { buildOnPageMetadata } from "event-service/modules/utility";
import { AddASCClickableHandlers, AddASCNavHandlers, exposeCaptureEvent, processStoredEvents, captureEvent, AddASCClickToCallHandlers } from "event-service/modules/capture";

import GTMTracker from "./gtmTracker/GTMTracker";

import useModal from "../hooks/useModal";
import AvailabilityForm from "./modal/content/AvailabilityForm";

import '../scss/php-scss.scss'
import '../scss/layout.scss'
import LayoutHTML from '../components/LayoutHTML'
import {navigate} from "../../.cache/gatsby-browser-entry";

export default function Layout({ children, seo, footerClassName,PageNotFound, pageImage }) {


    let ModalTitle = ""
    const pushToDataLayer = useDataLayer()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    for (const [key, value] of params) {
        if(key === "title"){
            ModalTitle = value
        }

    }

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      if (loaded) {
        exposeCaptureEvent();
        processStoredEvents();
        AddASCNavHandlers();
        AddASCClickableHandlers();
        AddASCClickToCallHandlers();
        captureEvent({
          event: 'asc_pageview',
          error_code: '',
          item_id: document.querySelector('data[id="vin"]')?.value || '',
          item_number: document.querySelector('data[id="stock"]')?.value || '',
          item_price: document.querySelector('data[id="price"]')?.value || '',
          item_condition: document.querySelector('data[id="condition"]')?.value || '',
          item_year: document.querySelector('data[id="year"]')?.value || '',
          item_make: document.querySelector('data[id="make"]')?.value || '',
          item_model: document.querySelector('data[id="model"]')?.value || '',
          item_variant: document.querySelector('data[id="trim"]')?.value || '',
          item_color: document.querySelector('data[id="color"]')?.value || '',
          item_type: document.querySelector('data[id="type"]')?.value || '',
          item_category: document.querySelector('data[id="category"]')?.value || '',
          item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
          item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
          item_results: document.querySelector('data[id="results"]')?.value || '',
        }, {
          handlers: ['ASC']
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
      }
    }, [loaded]);

    const [renderModal] = useModal({
        title: ModalTitle,
        location: "#contact-form"
    })

  return <StaticQuery
    query={pagesQuery}
    render={data => {
      const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
      if (!loaded) {
        setLoaded(siteSettings.dealerInfo)
        if (siteSettings.dealerFeedInfo) {
            buildOnPageMetadata({
                postalCode: siteSettings.dealerFeedInfo?.postalCode,
                state: siteSettings.dealerFeedInfo?.state,
                city: siteSettings.dealerFeedInfo?.city
            })
        }
      }
      const siteNav = JSON.parse(data.siteData.siteData.siteNav)

        if ((PageNotFound !== undefined) && (PageNotFound == true)){
            if (typeof window !== 'undefined'){
                if((siteSettings.dealerInfo.Redirect !== undefined) && (siteSettings.dealerInfo.Redirect === true)){
                    navigate('/')
                }
            }
        }


      const { theme } = siteSettings
      const html = siteSettings.html
      let primaryFont = "\"Overpass\", sans-serif"
      let secondaryFont = "\"Russo One\", sans-serif"

      const socialThumbnail= siteSettings.dealerInfo.socialThumbnail

      const rgbaTranslator = (hexCode, opacityValue) => {
        const rVal = parseInt(hexCode.substr(1,2), 16).toString()
        const gVal = parseInt(hexCode.substr(3,2), 16).toString()
        const bVal = parseInt(hexCode.substr(5,2), 16).toString()

        return "rgba(" + rVal +  ", " + gVal + ", " + bVal + ", " + opacityValue + ")"
      }

      const themeStyle = {
        primary: theme.colors.primary,
        secondary: theme.colors.secondary,
        primaryText: theme.colors.text,
        secondaryText: theme.colors.secondarytext,
        primaryAccent: theme.colors.accent1,
        secondaryAccent: theme.colors.accent2,
        primaryFont: primaryFont,
        secondaryFont: secondaryFont,
        primaryTranslucent: rgbaTranslator(theme.colors.primary, '0.7')
    }
    const theTheme =  {
        "--primary-color": themeStyle.primary,
        "--secondary-color": themeStyle.secondary,
        "--primary-text":  themeStyle.primaryText,
        "--secondary-text": themeStyle.secondaryText,
        "--primary-accent": themeStyle.primaryAccent,
        "--secondary-accent": themeStyle.secondaryAccent,
        "--primary-font": themeStyle.primaryFont,
        "--secondary-font": themeStyle.secondaryFont,
        "--primary-translucent": themeStyle.primaryTranslucent
      }

      const document = typeof window !== 'undefined' && window.document
      const modalContactContent = (
        <AvailabilityForm autofillable leadURL={siteMetadata.lead_url}
                          pageSource='Contact'
                          gtmId={siteSettings.dealerInfo.gtmId}
                          pageURL={document.URL}
                          siteId={siteMetadata.site_id} />
      )
      function formSubmit(contactElements, optionalFields, responseField) {

        let data = {}
        data.call = ''
        data.text = ''
        data.checkEmail = ''
        data.firstName = contactElements.firstName
        data.lastName = contactElements.lastName
        data.email = contactElements.email
        data.phoneNumber = contactElements.phone
        data.comments = contactElements.comments
        data.street = contactElements.street
        data.apartment  = contactElements.apartment
        data.city = contactElements.city
        data.regioncode = contactElements.regioncode
        data.postalcode = contactElements.postalcode
        data.siteId = siteMetadata.site_id
        data.pageSource = contactElements.pageSource
        data.pageURL = window.location.href

        let optionalData = '\n Additional information -'
        optionalFields.forEach(element => {
            optionalData += ' ' + element
        })
        data.comments += (optionalData != '\n Additional information -') ? optionalData : ''
        axios.post(siteMetadata.lead_url, JSON.stringify(data))
            .then(res => {
                pushToDataLayer("form-submission", res)
                pushToDataLayer("contact", res)
                if(responseField !== null) {
                  responseField.querySelector('.custom.contact-success').innerText = 'Success! The message has been sent!'
                  responseField.querySelector('.custom.contact-success').classList = 'contact-success'
                }
            })
            .catch(err => {
                console.log(err)
                if(responseField !== null) {
                  responseField.querySelector('.custom.contact-disclaimer').innerText = 'Uh oh! Something went wrong, please try again!'
                  responseField.querySelector('.custom.contact-disclaimer').classList = 'contact-failure'
                }
            })
            
    }

    if (typeof window !== 'undefined'){
        window.gatsbyNavigate = navigate;
        window.customFormSubmit = formSubmit;
        window.dealerInfo = siteSettings.dealerInfo;
    }
      return (
        <React.Fragment>
          {/*<Global styles={GlobalCSS(themeStyle)} />*/}
          { (seo !== undefined) &&<SEO key={"SEO"} title={"Layout"} data={seo} socialThumbnail={socialThumbnail} preloadImage={pageImage}/> }
          <Helmet>
            
          </Helmet>
          <style type="text/css">
            {
              `:root {
              --primary-color: ${themeStyle.primary};
              --secondary-color: ${themeStyle.secondary};
              --primary-text:  ${themeStyle.primaryText};
              --secondary-text: ${themeStyle.secondaryText};
              --primary-accent: ${themeStyle.primaryAccent};
              --secondary-accent: ${themeStyle.secondaryAccent};
              --primary-font: ${themeStyle.primaryFont};
              --secondary-font: ${themeStyle.secondaryFont};
              --primary-translucent: ${themeStyle.primaryTranslucent};
            }`
            }
          </style>

          <GTMTracker gtmId={siteSettings.dealerInfo.gtmId}/>

          <TopNavigation siteNav={siteNav} dealerInfo={siteSettings.dealerInfo} />
          <LayoutHTML data={html.topHtml} />
          { renderModal(modalContactContent) }

          <div className="body">
            {children}
          </div>

          <LayoutHTML data={html.bottomHtml} />
          <Footer footerClassName={footerClassName} siteNav={siteNav} footer={siteSettings.Footer} dealerInfo={siteSettings.dealerInfo} />
        </React.Fragment>
      )
    }}
  />
}

const pagesQuery = graphql`
{
  siteData {
      siteData {
      siteSettings
      siteNav
      siteMetadata {
          currentTemplateID
          siteName
      }
      }
  }
}
`
