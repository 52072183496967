import { css } from "@emotion/core"

// file all the global css like colors for classes that pull from the json data
const globalCSS = (theme) => css`
  :root {
    --primary-color: ${theme.primary};
    --secondary-color: ${theme.secondary};
    --primary-text: ${theme.primaryText};
    --secondary-text: ${theme.secondaryText};
    --primary-accent: ${theme.primaryAccent};
    --secondary-accent: ${theme.secondaryAccent};
    --primary-font: ${theme.primaryFont};
    --secondary-font: ${theme.secondaryFont};
    --primary-translucent: ${theme.primaryTranslucent};
  }
`
export default globalCSS