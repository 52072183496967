/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title, data, socialThumbnail, preloadImage }) {
    const metaDescription = data.seo.MetaDesc || description
    const metaTitle = data.seo.MetaTitle || title

    const metaTags = data.seo.tags
    // Tags for Testing
    // [{"tagName": "SiteColor","tagContent":"Blue","id":"\"SiteColorBlue\"","editState":false},
    // {"tagName": "DealerID","tagContent":"314","id":"\"DealerID314\"","editState":false}]

    let tags = []
    metaTags.forEach(tag => tags.push({ "name": tag.tagName, "content": tag.tagContent }));

    let dmSocialThumbnail = tags.find(tag => tag.name === 'dm:socialthumbnail');
    let ogImage = tags.find(tag => tag.name === 'og:image');
    dmSocialThumbnail = typeof dmSocialThumbnail != 'undefined' ? dmSocialThumbnail.content : ""
    ogImage = typeof ogImage != 'undefined' ? ogImage.content : ""
    const location = typeof window !== 'undefined' && window.location
    const domainLocation = location.href
    const urlLocation = location.host
    let socialImage = typeof socialThumbnail != 'undefined' ? socialThumbnail: ""
    if( dmSocialThumbnail != ""){
        socialImage = dmSocialThumbnail
    }else if(ogImage != ""){
        socialImage = ogImage
    }

    const getImageLink = ()=>
    {
        if (preloadImage){
            return <link rel="preload" as="image" href={preloadImage}></link>
        }
        else{
            return <link></link>
        }
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            meta={[
                {
                    name: `description`,
                    property: `description`,
                    content: metaDescription,
                },
                {
                    name: `title`,
                    property: `title`,
                    content: metaTitle,
                },
                {
                    name: "og:image", 
                    property: "og:image", 
                    content: socialImage,
                },
                {
                    name: "og:url",
                    property: "og:url",
                    content: domainLocation,
                },
                {
                    name: "og:title",
                    property: "og:title",
                    content: metaTitle,
                },
                {
                    name: "og:type",
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "og:description", 
                    property: "og:description", 
                    content: metaDescription,
                },
                {
                    name: "og:height",
                    property: "og:height",
                    content: "1200"
                },
                {
                    name: "og:width",
                    property: "og:width",
                    content: "630"
                },
                {
                    name: "twitter:card",
                    property: "twitter:card",
                    content: "summary_large_image"
                },
                {
                    name: "twitter:domain",
                    property: "twitter:domain",
                    content: domainLocation
                },
                {
                    name: "twitter:url",
                    property: "twitter:url",
                    content: urlLocation
                },
                {
                    name: "twitter:title",
                    property: "twitter:title",
                    content: metaTitle
                },
                {
                    name: "twitter:description",
                    property: "twitter:description",
                    content: metaDescription
                },
                {
                    name: "twitter:image:width",
                    property: "twitter:image:width",
                    content: "1200"
                },
                {
                    name: "twitter:image:height",
                    property: "twitter:image:height",
                    content: "630"
                },
                {
                    name: "twitter:image:alt",
                    property: "twitter:image:alt",
                    content: metaTitle
                },
                {
                    name: "twitter:image",
                    property: "twitter:image",
                    content: socialImage
                }
            ].concat(meta).concat(tags)}
        >
            <title>{metaTitle}</title>
            {getImageLink()}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
